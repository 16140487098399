<template>
  <div class="ActivitySignUp">
    <div class="keenMindDetail">
      <div v-loading="loading"></div>
      <div class="crumb" v-if="clientWidth > 750">
        <breadcrumb :refer="refer"></breadcrumb>
      </div>
      <div v-if="Isdata" class="keenMindDetail-banner" style="margin-top: 0">
        <img v-if="keenDetail.banner" :src="keenDetail.banner" alt="" />
        <img v-else src="@/assets/PC/keenMind/keendetailbanner.png" alt="" />
        <h1 v-if="!keenDetail.banner" class="hidden">{{ keenDetail.title }}</h1>
        <p v-if="!keenDetail.banner" class="flex1">
          <van-icon name="clock" class="mr co-voi" />
          {{ keenDetail.startTimeStr }} - {{ keenDetail.endTimeStr }}
        </p>
      </div>
      <div v-if="Isdata" class="ActivitySignUp-content">
        <el-form :model="form" ref="form" :rules="rule" label-width="80px">
          <div class="flex InfoForm">
            <div class="Infos">
              <el-form-item label="姓名" prop="name">
                <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
              </el-form-item>
              <!-- <el-form-item label="职务" prop="scope">
                <el-select v-model="form.scope" placeholder="请选择您的职务">
                  <el-option v-for="item in SelectModules.scope" :label="item.text" :value="item.value" :key="item.value"></el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="职务" prop="scope">
                <el-cascader
                  v-model="ScodeItems"
                  :options="SelectModule.position"
                  placeholder="请选择职务"
                  :props="props1"
                  @change="ScodeItem1"></el-cascader>
                <el-form-item prop="scopeOther" v-if="IsOther" style="margin-top: 10px;">
                  <el-input v-model.trim="form.scopeOther" placeholder="请输入职务"></el-input>
                </el-form-item>
              </el-form-item>
              <el-form-item label="邮箱" prop="email">
                <el-input v-model="form.email" placeholder="请输入您的邮箱"></el-input>
              </el-form-item>
            </div>
            <div class="Infos">
              <el-form-item label="公司">
                <el-input v-model="form.company" placeholder="请输入您的公司" disabled></el-input>
              </el-form-item>
              <el-form-item label="手机号" prop="phone">
                <el-input v-model="form.phone" @blur="phonechange" placeholder="请输入您的手机号"></el-input>
              </el-form-item>
              <el-form-item v-if="phoneChangeState" label="验证码" prop="code">
                <div class="flex2">
                  <el-input v-model="form.code" placeholder="请输入验证码"></el-input>
                  <el-button v-if="codeShow" @click="getPhoneCode" class="getCode ml Iscode">获取验证码</el-button>
                  <el-button class="getCode ml" v-else disabled>{{ count }} 秒后重试</el-button>
                </div>
              </el-form-item>
            </div>
          </div>
          <div class="ActivitySignUp-title flex2 violet mb15"> 上述信息中的【姓名】请务必填写真实姓名</div>
          <div v-if="keenDetail.classify != 2" class="description" v-html="keenDetail.needingContent" style="padding-bottom: 0"></div>

          <div v-if="keenDetail.classify != 2 && this.keenDetail.needingContent" class="ActivitySignUp-authorization flex5 mt15">
            <el-radio v-model="privacy" label="true" class="flex2">同意</el-radio>
          </div>

          <div class="ActivitySignUp-operation tc mt15">
            <el-button :loading="loading1" type="info" @click="signUp('form')">提 交</el-button>
          </div>
        </el-form>
      </div>

      <div v-if="sendFile" class="shade flex0">
      <div class="userAlert tc">
        <i class="el-icon-circle-close co-fff cur" @click="goActivityFile"></i>
        <p class="userAlert-text0"><br></p>
        <h2 class="violet userAlert-text1">您已成功提交报名，<br>点击下方按钮即刻前往提交作品！</h2>
        <p class="userAlert-text0"></p>
        <div class="flex0 userAlert-btn">
          <el-button type="info" @click="goActivityFile">提交作品</el-button>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from "@/components/Breadcrumb/index.vue";
import { common_msg_update } from "@/utils/common.js";
import * as Keen from "@/api/KeenMind.js";
import { dataState } from "@/api/all.js";
import { getCode } from "@/api/register.js";

export default {
  name: "ActivitySignUp",
  data() {
    return {
      props1: { multiple: false, value: 'value', label: 'text', children: 'list' },
      loading: true,
      loading1: false, // !提交按钮的loading
      Info: this.$store.state.Infos,
      SelectModules: this.$store.state.Module,
      ScodeItems: '',       // !职务单选
      IsOther: false,       // !职务其他
      refer: [
        { name: "首页", href: "/" },
        { name: "剑鸣", href: "/Home/keenMind" },
      ],
      keenDetail: {}, // !详情页数据
      Isdata: false,
      form: {
        name: "", // !姓名
        email: "", // !邮箱
        phone: "", // !手机号
        code: "", // !验证码
        company: "", // !公司
        // scope: "", // !职务
        scope: "",          // !工作职能二级
        scopeOther: ""      // !其他
      },
      copyPhone: "", // !获取验证码验证手机号是否改变
      phoneChangeState: false, // !是否显示验证码输入框
      codeShow: true,
      count: 0,
      privacy: false, // !授权阅读
      sendFile: false, // !报名成功页面
      rule: {
        name: [
          { required: true, message: "请输入您的姓名", trigger: "blur" },
          {
            min: 2,
            max: 30,
            message: "长度在2个字符至30个字符之间",
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "请输入您的邮箱地址", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入您的手机号", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        scope: [{ required: true, message: "请选择职务", trigger: "blur" }],
        scopeOther: [{ required: true, message: "请输入职务", trigger: "blur" }]
      },
    };
  },
  mounted() {
    if(!this.$store.state.token) {
      this.$router.push('/Home/keenMindDetail?id=' + this.$route.query.id);
    }
    if (!this.$route.query.id) {
      this.$toast("页面异常，正在返回首页");
      setTimeout(() => {
        this.$router.push("/Home/keenMind");
      }, 500);
      return false;
    }
    this.Init();
    this.InfoInit();

    // 数据统计
    const data = {
      visitType: 1,
      gid: sessionStorage.getItem("gid") ? sessionStorage.getItem("gid") : "",
      url: window.location.href,
      pageUrl: "ActivitySignUp",
      relationId: this.$route.query.id,
    };
    dataState(data).then((res) => {
      sessionStorage.setItem("gid", res.data);
    });
  },
  methods: {
    // !获取详情数据
    Init() {
      const Id = { id: this.$route.query.id };
      Keen.GetActivityDetail(Id).then((res) => {
        if (res.status == 1) {
          if(res.data.isSignUp) {
            this.$toast('本次活动已报名！');
            this.$router.push("/Home/keenMindDetail?id=" + this.$route.query.id);
          }

          this.keenDetail = res.data;
          if (this.refer.length < 3) {
            this.refer.push({
              name: res.data.title,
              href: "/Home/keenMindDetail?id=" + this.$route.query.id,
            });
            this.refer.push({ name: "我要报名" });
          }
          this.Isdata = true;
        } else {
          this.$toast(res.message);
          this.$router.push("/Home/keenMind");
        }
        this.loading = false;
      });
    },
    // !表单填充
    InfoInit() {
      if(this.Info.id) {
        // const scope = this.SelectModules.scope.filter(item => item.value == this.Info.scope)
        // 职务
        this.ScodeItems = [this.Info.positionId + "", this.Info.scope + ""]
        var otherName
        this.SelectModule.position.filter(item => {
          item.list.filter(val => {
            if(val.value == this.Info.scope){
              otherName = val.text
            }
          })
        })
        if (this.Info.scopeOther || otherName == '其他') {
          this.IsOther = true
        }
        this.form = {
          name: this.Info.name,
          email: this.Info.email,
          phone: this.Info.phone,
          company: this.Info.company,
          scope: this.Info.scope,
          scopeOther: this.Info.scopeOther ? this.Info.scopeOther : '',
          code: ''
        }
        this.copyPhone = this.Info.phone;
      }
      
    },
    // 职务选择
    ScodeItem1() {
      this.form.positionId = this.ScodeItems[0]
      this.form.scope = this.ScodeItems[1]
      var str
      this.$store.state.Module.position.filter(item => {
        item.list.filter(val => {
          if(val.value == this.ScodeItems[1]){
            str = val.text
          }
        })
      })
      if(str == '其他') {
        this.IsOther = true
      } else {
        this.IsOther = false
        this.form.scopeOther = ""
      }
    },
    // !提交报名
    signUp(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if((this.keenDetail.needingContent && this.privacy !== "true" && this.keenDetail.classify == 1)) {
            this.$toast('请您仔细阅读以下承诺书，并勾选同意！');
            return false;
          }
          this.loading1 = true;
          const Data = JSON.parse(JSON.stringify(this.form));
          Data.scope = Data.scope * 1;
          Data.activityId = this.$route.query.id;
          delete Data.company;
          Keen.SignUpClassify(Data).then(res => {
            if(res.status == 1) {
              common_msg_update();
              if(this.keenDetail.type == 1){
                this.$toast('报名成功！');
                setTimeout(() => {
                  this.$router.push('/Home/keenMindDetail?id=' + this.$route.query.id)
                }, 500);
              } else {
                this.sendFile = true;
              }
            } else {
              this.loading1 = false;
              this.$toast(res.message);
            }
          })
        }
      })
    },
    // !检测是否更换手机号
    phonechange() {
      if(this.copyPhone == this.form.phone) {
        this.phoneChangeState = false;
      } else {
        this.phoneChangeState = true;
      }
    },
    // !获取手机验证码
    getPhoneCode() {
      const phone = this.form.phone;
      if (!phone) {
        this.$toast({ message: "请先输入您的手机号！" });
        return false;
      } else if (!validator(phone)) {
        this.$toast({ message: "请输入正确的手机号！" });
        return false;
      } else {
        getCode({ phone: phone, type: 2 }).then((res) => {
          if (res.status == 1) {
            this.$toast({ message: "验证码发送成功!", duration: 1000 });
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.codeShow = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.codeShow = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            this.$toast({ message: res.message, duration: 2000 });
          }
        });
      }
      // 正则验证格式
      function validator(value) {
        var reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        return RegExp(reg).test(value);
      }
    },
     // !去写文章页面
    goActivityFile() {
      this.$router.push('/Home/ActivityFile?id=' + this.$route.query.id);
    },
  },
  computed: {
    clientWidth () {
      return this.$store.state.clientWidth;
    },
    SelectModule() {
      return this.$store.state.Module;
    },
    Infos() {
      return this.$store.state.Infos;
    },
    // activityDetail() {
    //   return this.$store.state.activityDetail
    // }
  },
  watch: {
    Infos(val) {
      this.Info = val;
      this.InfoInit();
    },
    SelectModule(val) {
      this.SelectModules = val;
      this.InfoInit();
    }
  },
  components: {
    breadcrumb,
  },
};
</script>

<style lang="scss">
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

.ActivitySignUp-title { line-height: 41px; background: #edcdf1; font-size: 14px; padding-left: 16px; font-weight: bold; }
.ActivitySignUp-authorization  .el-radio__label{color: #606266!important; padding-left: 5px;}
.ActivitySignUp-authorization .el-checkbox__input {display: flex; align-items: center;}

@media (max-width: 750px) {
  .InfoForm {display: block;}
  .ActivitySignUp {
    .userAlert { min-height: 230px; }
    .ActivitySignUp-title {margin: vw(20) 0; line-height: vw(48); font-size: vw(24); padding-left: vw(11);}
    .el-form-item__label {line-height: vw(60);}
    .userAlert-text1 {font-size: vw(36);}
  }
  
}
</style>